import React, { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import clsx from 'clsx';
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from '@material-ui/core';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  toolbar: theme.mixins.toolbar,
  listItemIcon: {
    minWidth: '48px',
  },
  listItemDisabled: {
    opacity: 0.5,
  },
}));

function Menu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  useLayoutEffect(() => {
    const toggleMenuOnResize = () => {
      (window as any).innerWidth < 576
        ? dispatch(actions.doHideMenu())
        : dispatch(actions.doShowMenu());
    };

    toggleMenuOnResize();

    (window as any).addEventListener(
      'resize',
      toggleMenuOnResize,
    );

    return () => {
      (window as any).removeEventListener(
        'resize',
        toggleMenuOnResize,
      );
    };
  }, [dispatch]);

  const selectedSubKeys = (submenus) => {
    const url = props.url;

    if (submenus) {
      const match = submenus.find((option) => {
        return (
          url === option.path ||
          url.startsWith(option.path + '/')
        );
      });
      if (match) {
        return [match.path];
      }
    }

    return [];
  };

  const selectedKeys = (menu) => {
    const ssk = selectedSubKeys(menu.subMenus);
    // if (!menu.subMenus) {
    //   return true;
    // }
    if (ssk.length > 0) {
      return true;
    }
    return false;
  };

  const CustomRouterLink = (props) => (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
        {...props}
      />
    </div>
  );

  if (!menuVisible) {
    return null;
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar}></div>

      <List>
        {menus.map((menu) => (
          <CustomRouterLink key={menu.path} to={menu.path}>
            <ListSubheader>
              <ListItemText
                // className={clsx({
                //   [classes.active]: selectedKeys().includes(
                //     menu.path,
                //   ),
                // })}
                className={clsx({
                  [classes.active]: selectedKeys(menu),
                })}
              >
                {menu.label}
              </ListItemText>

              {menu.subMenus
                ? menu.subMenus.map((submenu) => (
                    <CustomRouterLink
                      key={submenu.path}
                      to={submenu.path}
                    >
                      <ListItem button>
                        <ListItemText
                          className={clsx({
                            [classes.active]: selectedSubKeys(
                              menu.subMenus,
                            ).includes(submenu.path),
                          })}
                        >
                          {submenu.label}
                        </ListItemText>
                      </ListItem>
                    </CustomRouterLink>
                  ))
                : null}
            </ListSubheader>
          </CustomRouterLink>
        ))}
      </List>
    </Drawer>
  );
}

export default Menu;


import Roles from 'src/security/roles'
import Plans from 'src/security/plans'
import Storage from 'src/security/storage'

const allPlans		= [ Plans.values.free, Plans.values.growth, Plans.values.enterprise ]
const settingsStorage	= [ Storage.values.settingsBackgroundImages, Storage.values.settingsLogos ]

const defaultPermissions =
{
	allowedRoles : [ Roles.values.admin ],
	allowedPlans : allPlans,
	allowedStorage : []
}

const customPermissions =
{
	allowedRoles : [ Roles.values.admin, Roles.values.custom ],
	allowedPlans : allPlans,
	allowedStorage : []
}

const validCategories =
[
	'auditLog',
	'comparativeEffectiveness',
	'complications',
	'costs',
	'economic',
	'economicBurden',
	'incidence',
	'models',
	'patient',
	'patientBurden',
	'plan',
	'prognosticCharacteristics',
	'realWorldOutcomes',
	'realWorldTreatmentOutcomes',
	'realWorldTreatmentOutcomes2',
	'realWorldTreatmentPatterns',
	'resourceUtilization',
	'searchDataEconomic',
	'searchStrategy',
	'searchStrategyRwo',
	'secondaryCancers',
	'settings',
	'survival',
	'tenant',
	'uiComparativeEffectiveness',
	'uiCostsHru',
	'uiEpidemiology',
	'uiReferences',
	'user'
]

let permissions = {}

export default class Permissions
{
	static AUTOCOMPLETE	= 0
	static CREATE		= 1
	static DESTROY		= 2
	static EDIT		= 3
	static IMPORT		= 4
	static READ		= 5

				// Most permissions are currently the default. Turn em all on, then turn a few off.
	constructor()
	{
		for ( let c = 0; c < validCategories.length; c++ )
		{
			const cat = validCategories[c]

			for ( let p = Permissions.AUTOCOMPLETE; p <= Permissions.READ; p++ )
			{
/*
if (cat==='survival' || cat==='secondaryCancers')
{
	permissions[cat][p] = null
	continue;
}
*/
				permissions[cat][p] =
					(p === Permissions.AUTOCOMPLETE || p === Permissions.READ) ? customPermissions : defaultPermissions
			}
		}
				// Exceptions:
				//	`auditLog` and `searchStrategy` only haev READ
				//	`tenant` has only DESTROY
				//	`plan` has only EDIT & READ
				//	`settings` has only EDIT, in which allowedStorage = settingsStorage

		permissions['auditLog'] = permissions['searchStrategy'] = [ null, null, null, null, null, defaultPermissions ]
		permissions['tenant'] = [ null, null, defaultPermissions, null, null, null ]
		permissions['plan'] = [ null, null, null, defaultPermissions, null, defaultPermissions ]
		permissions['settings'] = [ null, null, null, defaultPermissions, null, null ]
		permissions['settings'][Permissions.EDIT].allowedStorage = settingsStorage
	
	}

	static get( cat : string , perm : number )
	{
		if ( ! validCategories[cat] || perm < Permissions.AUTOCOMPLETE || perm > Permissions.READ )
			return {}

		if ( ! permissions[cat][perm] )
			return {}

		return permissions[cat][perm]
	}
}

/**
 *
 * Creator: emids, inc.
 * Contact: lee.eldridge@emids.com
 * Version: 0.1
 *
 */

import { useEffect } from 'react';
import { i18n } from 'src/i18n';
import { useParams } from 'react-router-dom';
import actions from 'src/modules/auth/authActions';
import layoutActions from 'src/modules/layout/layoutActions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import selectors from 'src/modules/auth/authSelectors';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: '1.5em',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    textDecoration: 'none',
  },
  grow: {
    flex: '1 1 auto',
  },
}));

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let { tenant } = useParams();
  const tenantId = AuthCurrentTenant.get();
  const tenantUrl = `/t/${tenantId}`;

  useEffect(() => {
    if (tenant) {
      dispatch(actions.doSigninTenant(tenant));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const logoUrl = useSelector(selectors.selectLogoUrl);

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton
          edge="start"
          onClick={doToggleMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Link className={classes.logo} to={tenantUrl}>
          {logoUrl ? (
            <img
              src={logoUrl}
              width="164px"
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle' }}
            />
          ) : (
            <>{i18n('app.title')}</>
          )}
        </Link>

        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;

import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.complications.fields.id'),
  },
  {
    name: 'regionSel',
    label: i18n('entities.complications.fields.regionSel'),
  },
  {
    name: 'yearSel',
    label: i18n('entities.complications.fields.yearSel'),
  },
  {
    name: 'patientPopSel',
    label: i18n('entities.complications.fields.patientPopSel'),
  },
  {
    name: 'study',
    label: i18n('entities.complications.fields.study'),
  },
  {
    name: 'year',
    label: i18n('entities.complications.fields.year'),
  },
  {
    name: 'studyType',
    label: i18n('entities.complications.fields.studyType'),
  },
  {
    name: 'country',
    label: i18n('entities.complications.fields.country'),
  },
  {
    name: 'data',
    label: i18n('entities.complications.fields.data'),
  },
  {
    name: 'timePeriod',
    label: i18n('entities.complications.fields.timePeriod'),
  },
  {
    name: 'patientPopulation',
    label: i18n('entities.complications.fields.patientPopulation'),
  },
  {
    name: 'complication',
    label: i18n('entities.complications.fields.complication'),
  },
  {
    name: 'endpoints',
    label: i18n('entities.complications.fields.endpoints'),
  },
  {
    name: 'results',
    label: i18n('entities.complications.fields.results'),
  },
  {
    name: 'count',
    label: i18n('entities.complications.fields.count'),
  },
  {
    name: 'rowIsVisible',
    label: i18n('entities.complications.fields.rowIsVisible'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rowColor',
    label: i18n('entities.complications.fields.rowColor'),
  },
  {
    name: 'country2',
    label: i18n('entities.complications.fields.country2'),
  },
  {
    name: 'africa',
    label: i18n('entities.complications.fields.africa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'asiaPacific',
    label: i18n('entities.complications.fields.asiaPacific'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'australia',
    label: i18n('entities.complications.fields.australia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'europe',
    label: i18n('entities.complications.fields.europe'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'latinAmerica',
    label: i18n('entities.complications.fields.latinAmerica'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'middleEast',
    label: i18n('entities.complications.fields.middleEast'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'usa',
    label: i18n('entities.complications.fields.usa'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'canada',
    label: i18n('entities.complications.fields.canada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result',
    label: i18n('entities.complications.fields.result'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'value',
    label: i18n('entities.complications.fields.value'),
  },
  {
    name: 'result2',
    label: i18n('entities.complications.fields.result2'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cll',
    label: i18n('entities.complications.fields.cll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rrCll',
    label: i18n('entities.complications.fields.rrCll'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registryDb',
    label: i18n('entities.complications.fields.registryDb'),
  },
  {
    name: 'claimsDatabase',
    label: i18n('entities.complications.fields.claimsDatabase'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'eap',
    label: i18n('entities.complications.fields.eap'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'literature',
    label: i18n('entities.complications.fields.literature'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'medicalCenters',
    label: i18n('entities.complications.fields.medicalCenters'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'registry',
    label: i18n('entities.complications.fields.registry'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'result4',
    label: i18n('entities.complications.fields.result4'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'ext1',
    label: i18n('entities.complications.fields.ext1'),
  },
  {
    name: 'ext2',
    label: i18n('entities.complications.fields.ext2'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.complications.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.complications.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

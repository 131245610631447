const backendUrl = `https://dev-api.lrsm.w2o-ops.com/api`;

/**
 * Frontend Url.
 */
const frontendUrl = {
  host: 'https://dev.lrsm.w2o-ops.com',
  protocol: 'https',
  
};

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'multi';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey = '';

export default {
  frontendUrl,
  backendUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey
};

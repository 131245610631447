const secondaryCancersEnumerators = {
  regionSel: [
    'Africa',
    'AsiaPacific',
    'Australia',
    'Europe',
    'LatinAmerica',
    'MiddleEast',
    'USA',
    'Canada',
  ],
  yearSel: ['2015', '2016', '2017', '2018', '2019', '2020'],
  patientPopSel: ['CLL', 'rrCll'],
  dataSourceSel: [
    'ClaimsDatabase',
    'eap',
    'Literature',
    'MedicalCenters',
    'Registry',
  ],
};

export default secondaryCancersEnumerators;

import list from 'src/modules/realWorldTreatmentOutcomes/list/realWorldTreatmentOutcomesListReducers';
import form from 'src/modules/realWorldTreatmentOutcomes/form/realWorldTreatmentOutcomesFormReducers';
import view from 'src/modules/realWorldTreatmentOutcomes/view/realWorldTreatmentOutcomesViewReducers';
import destroy from 'src/modules/realWorldTreatmentOutcomes/destroy/realWorldTreatmentOutcomesDestroyReducers';
import importerReducer from 'src/modules/realWorldTreatmentOutcomes/importer/realWorldTreatmentOutcomesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

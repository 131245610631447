import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'area',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.area',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.area',
      ),
      {},
    ),
  },
  {
    name: 'subarea',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.subarea',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.subarea',
      ),
      {},
    ),
  },
  {
    name: 'study',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.study',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.study',
      ),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.publicationType',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.publicationType',
      ),
      {},
    ),
  },
  {
    name: 'studyType',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.studyType',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.studyType',
      ),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.country',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.country',
      ),
      {},
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.patientPopulation',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.patientPopulation',
      ),
      {},
    ),
  },
  {
    name: 'interventions',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.interventions',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.interventions',
      ),
      {},
    ),
  },
  {
    name: 'response',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.response',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.response',
      ),
      {},
    ),
  },
  {
    name: 'os',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.os',
    ),
    schema: schemas.boolean(
      i18n('entities.realWorldTreatmentOutcomes.fields.os'),
      {},
    ),
  },
  {
    name: 'pfs',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.pfs',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.pfs',
      ),
      {},
    ),
  },
  {
    name: 'aes',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.aes',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.aes',
      ),
      {},
    ),
  },
  {
    name: 'pros',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.pros',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.pros',
      ),
      {},
    ),
  },
  {
    name: 'adherence',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.adherence',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.adherence',
      ),
      {},
    ),
  },
  {
    name: 'discontinuation',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.discontinuation',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.discontinuation',
      ),
      {},
    ),
  },
  {
    name: 'treatmentPatterns',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns',
    ),
    schema: schemas.string(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns',
      ),
      {},
    ),
  },
  {
    name: 'treatmentPatterns2',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns2',
    ),
    schema: schemas.boolean(
      i18n(
        'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns2',
      ),
      {},
    ),
  },
];

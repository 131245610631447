import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class UiReferencesService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/ui-references`,
      {
        params,
      },
    );

    // Changing in the client-side services until API supports tenants fully.
    //return response.data;
    return { rows: response.data, count: 1 };
  }
}

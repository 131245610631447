import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.id',
    ),
  },
  {
    name: 'area',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.area',
    ),
  },
  {
    name: 'subarea',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.subarea',
    ),
  },
  {
    name: 'study',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.study',
    ),
  },
  {
    name: 'publicationType',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.publicationType',
    ),
  },
  {
    name: 'studyType',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.studyType',
    ),
  },
  {
    name: 'country',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.country',
    ),
  },
  {
    name: 'patientPopulation',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.patientPopulation',
    ),
  },
  {
    name: 'interventions',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.interventions',
    ),
  },
  {
    name: 'response',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.response',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'os',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.os',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'pfs',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.pfs',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'aes',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.aes',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'pros',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.pros',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'adherence',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.adherence',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'discontinuation',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.discontinuation',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'treatmentPatterns',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns',
    ),
  },
  {
    name: 'treatmentPatterns2',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.treatmentPatterns2',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.realWorldTreatmentOutcomes.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];

import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/realWorldTreatmentOutcomes/importer/realWorldTreatmentOutcomesImporterSelectors';
import RealWorldTreatmentOutcomesService from 'src/modules/realWorldTreatmentOutcomes/realWorldTreatmentOutcomesService';
import fields from 'src/modules/realWorldTreatmentOutcomes/importer/realWorldTreatmentOutcomesImporterFields';
import { i18n } from 'src/i18n';

const realWorldTreatmentOutcomesImporterActions = importerActions(
  'REALWORLDTREATMENTOUTCOMES_IMPORTER',
  selectors,
  RealWorldTreatmentOutcomesService.import,
  fields,
  i18n('entities.realWorldTreatmentOutcomes.importer.fileName'),
);

export default realWorldTreatmentOutcomesImporterActions;